import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { HeroImageRight } from '../components/General/HeroImageRight';
import { HeroVideo } from '../components/General/HeroVideo';
import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import { FeatureFAQ } from '../components/ProductFeature/FeatureFAQ';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import { Partners } from '../components/ApiIntegrations/Partners';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	heroVideo: {
		marginBottom: '5rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '3rem',
		},
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '16rem 0',

		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0 8rem 0',
		},
	},
}));

export default function ProductFeature({ data, location }) {
	const classes = useStyles();
	//console.log(data);
	const apiIntegrations = data.apiIntegrations.edges[0].node;
	//state for selected card for features
	const [selectedFeature, setSelectedFeature] = useState(null);

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	useEffect(() => {
		setSelectedFeature(apiIntegrations.featureSection[0]?._key);
	}, []);

	const {
		_id,
		metaTitle,
		metaDescription,
		marketoId,
    pardotUrl,
		contactForm,
		hero,
		heroImage,
		heroVideo,
		heroAlignCenter,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
		cta,
		partnerCardsHeader,
		partnerCards,
	} = apiIntegrations;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			{heroAlignCenter && heroVideo ? (
				<div className={classes.heroVideo}>
					<Hero hero={hero} feature />
					<Container>
						<HeroVideo wistiaLink={heroVideo} />
					</Container>
				</div>
			) : heroAlignCenter ? (
				<Hero hero={hero} feature />
			) : (
				<HeroImageRight
					_id={_id}
					hero={hero}
					heroImage={heroImage?.asset?.gatsbyImageData}
					feature
				/>
			)}
			<ProductFeatureBody
				body={apiIntegrations}
				cta={cta}
				partnerCards={partnerCards}
				partnerCardsHeader={partnerCardsHeader}
			/>

			{/* <WaveUpSVG height='213' width='100%' fill='#fff' /> */}
			<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
			<div>
				<WaveDownSVG height='213' width='100%' fill={'#FFFFFF'} />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				{!!selectedFeature ? (
					<Form
						formId={marketoId}
            pardotUrl={pardotUrl}
						contactForm={contactForm}
						privacy
						modal={false}
						location={location}
					/>
				) : null}
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
}

export const query = graphql`
	query ApiIntegrationsPageQuery {
		apiIntegrations: allSanityApiIntegrations {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaButtonText
						ctaButtonLink
						internalLink
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					heroVideo
					heroAlignCenter
					_rawOverviewContent
					overviewImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					featureHeader
					featureSection {
						_key
						title
						cardTitle
						cardFaIcon
						cardIconColor {
							hexValue
						}
						bodySubHeader
						bodyHeader
						image {
							featureImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						videoVariant
						androidLink
						iosLink
						qbLink
						# svgVariant
						thumbnailVideoUrl
						ctaText
						ctaLink
						_rawBodyCopy
						internalLink
					}
					partnerCardsHeader
					partnerCards {
						_key
						header
						logo {
							asset {
								gatsbyImageData
							}
						}
						body
						link
					}
					testimonialTitle
					testimonial {
						title
						header
						testimonialLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						company
						testimonialText
						nameAndTitle
						videoVariant
						image {
							asset {
								gatsbyImageData(
									fit: FILLMAX
									height: 450
									width: 775
									placeholder: BLURRED
								)
							}
						}
					}
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					marketoId
          pardotUrl
					customerLogosHeader
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
				}
			}
		}
	}
`;
